exports.components = {
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-datenschutzerklaerung-js": () => import("./../../../src/pages/datenschutzerklaerung.js" /* webpackChunkName: "component---src-pages-datenschutzerklaerung-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-alternating-alternating-js": () => import("./../../../src/templates/alternating/alternating.js" /* webpackChunkName: "component---src-templates-alternating-alternating-js" */),
  "component---src-templates-carousel-carousel-js": () => import("./../../../src/templates/carousel/carousel.js" /* webpackChunkName: "component---src-templates-carousel-carousel-js" */),
  "component---src-templates-grid-grid-js": () => import("./../../../src/templates/grid/grid.js" /* webpackChunkName: "component---src-templates-grid-grid-js" */)
}

