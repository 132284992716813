import React, { createContext } from "react";
import { graphql, useStaticQuery } from "gatsby";
export const GlossaryContext = createContext({});

const Provider = ({ children }) => {
  const data = useStaticQuery(graphql`
    {
      glossary {
        internal {
          content
        }
      }
    }
  `);

  const glossaryJSON = data.glossary?.internal?.content;
  const glossary = JSON.parse(glossaryJSON);

  return (
    <GlossaryContext.Provider value={glossary}>
      {children}
    </GlossaryContext.Provider>
  );
};

export default Provider;
